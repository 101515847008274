import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'APLICACIONES',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            /*{
                id       : 'perfil',
                title    : 'Empresa',
                translate: 'NAV.PERFIL.TITLE',
                type     : 'item',
                icon     : 'person_outline',
                url      : '/app/empresa'
            },*/
            {
                id       : 'compania',
                title    : 'Inicio',
                translate: 'NAV.COMPANIA.TITLE',
                type     : 'item',
                icon     : 'store_mall_directory',
                url      : '/app/compania'
            },
            /*{
                id       : 'facturacion',
                title    : 'Nuevo CFDI',
                translate: 'NAV.FACTURACION.TITLE',
                type     : 'item',
                icon     : 'assignment',
                url      : '/app/facturacion'
            },*/
            {
                id       : 'historial-facturacion',
                title    : 'CFDIs',
                translate: 'NAV.HISTORIAL-FACTURACION.TITLE',
                type     : 'item',
                icon     : 'folder_open',
                url      : '/app/historialFacturacion'
            },
            /*{
                id       : 'clientes',
                title    : 'Clientes',
                translate: 'NAV.HISTORIAL-FACTURACION.TITLE',
                type     : 'item',
                icon     : 'person',
                url      : '/app/clientes'
            },
            {
                id       : 'productos',
                title    : 'Productos',
                translate: 'NAV.HISTORIAL-FACTURACION.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/app/productos'
            }*/
        ]
    }
];
