import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AuthGuard} from './guard/auth.guard'
import { AuthService } from './services/auth.service';

import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component'



const routes: Routes = [
  {
    path      : 'login',
    loadChildren: 'app/login/login.module#LoginModule',
    canActivate: [AuthGuard]
  },
  {
    path      : 'registro',
    loadChildren: 'app/main/registro/registro.module#RegistroModule',
  },
  {
    path      : 'app',
    loadChildren: 'app/layout/vertical/layout-1/layout-1.module#VerticalLayout1Module',
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
  
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
