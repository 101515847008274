export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'APLICACIONES',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'PERFIL'        : {
                'TITLE': 'Perfil'
            },
            'COMPANIA'        : {
                'TITLE': 'Compañia'
            },
            'FACTURACION'        : {
                'TITLE': 'Facturacion'
            },
            'HISTORIAL-FACTURACION'        : {
                'TITLE': 'Historial de facturacion'
            },
            'CLIENTES'        : {
                'TITLE': 'Clientes'
            },
            'PRODUCTOS'        : {
                'TITLE': 'Productos'
            }
        }
    }
};
