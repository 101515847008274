export class CustomerUser{
    id: string;
    razon_social: string;
    nombre_comercial: string;
    RFC: string;
    CFDI: string;
    companyUserId: string;
    forma_pago: string;
    metodo_pago: string;

    constructor(CustomerUser?){
        CustomerUser = CustomerUser || {};
        this.id = CustomerUser.id || null;
        this.razon_social = CustomerUser.razon_social || '';
        this.nombre_comercial = CustomerUser.nombre_comercial || '';
        this.RFC = CustomerUser.RFC || '';
        this.CFDI = CustomerUser.CFDI || '';
        this.companyUserId = CustomerUser.companyUserId || '';
        this.forma_pago = CustomerUser.forma_pago || '';
        this.metodo_pago = CustomerUser.metodo_pago || '';
    }


    public static arrayObject(arreglo: any): CustomerUser[]{
        let arregloRe: CustomerUser[] = [];
        for (let value of arreglo) {
            arregloRe.push(new CustomerUser(value));
        }
        return arregloRe;
    }
}