import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { UserInterfacace } from '../models/user-interface';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken = localStorage.getItem("token");
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  private url_base = environment.apiUrl;
  public configSessionSocial: boolean = false;
  public configSessionCrearCount: boolean = false;

  constructor(private httclient: HttpClient) { }
  
  //Registrar usuario nuevo
  registerUser(user: any){
    const url_api = this.url_base + "companyUsers";
    return this.httclient.post<UserInterfacace>(url_api, user , { headers: this.headers }).pipe(map(data => data));
  }
  //Iniciar session de usuario ya registrado
  loginUser(user : UserInterfacace): Observable<any>{
    const url_api = this.url_base + "companyUsers/login?include=user";
    return this.httclient.post<UserInterfacace>(url_api,  user , { headers: this.headers }).pipe(map(data => data));
  }
  //set usuario que inicio sesion en un localStorage
  
  
  setUser(user: UserInterfacace, callback): void{
    let user_string = JSON.stringify(user);
    localStorage.setItem("currentUser", user_string);
		this.find('companies', '{"where": { "companyUserId": "'+user.id+'" }}').subscribe(data => {
      console.log('data', data)
      if(data.length > 0) {
        let company = JSON.stringify({codigo_postal: data[0].codigo_postal, id: data[0].id, nombre: data[0].nombre});
        localStorage.setItem("company", company);
        callback();
      } else {
        console.log('cero')
      }
		}, error => {
			console.log('error', error)
		});
    
  }
  
  //set token de inicio de sesion de usuario
  setToken(token: string): void{
    localStorage.setItem("token", token);
    this.accessToken = localStorage.getItem("token");
  }
  //Recuperar token de inicio de sesion de usuario
  getToken(){
	return localStorage.getItem("token");
  }
  //Recuperar actual usuario en la sesion
  getCurrentUser(): UserInterfacace{
	  let user_string = localStorage.getItem("currentUser");
	  if(!isNullOrUndefined(user_string)){
		let user: UserInterfacace = JSON.parse(user_string);
		return user;
	  }else {
		  return null;
	  }
  }
  //Desloguear usuario de la sesion y remover localStorage
  logoutUser(){
	  const url_api = this.url_base + `companyUsers/logout?access_token=${this.accessToken}`;
	  localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
	  localStorage.removeItem("company");
	  return this.httclient.post<UserInterfacace>(url_api, { headers: this.headers })
  }

  /**
	 * Metodo para subir archivos.
	 * @param file File.
	*/
  uploadFiles(file: File, name: string): Observable<any>{
    const url_api = this.url_base + "storages/CSD/upload";
    let formData: FormData = new FormData();
    formData.append('file', file, name + '.'+ file.name.split('.').pop());
    return this.httclient.post<UserInterfacace>(url_api,  formData).pipe(map(data => data));
  }

  /**
	 * Metodo para buscar modelos.
	 * @param modelo Modelo a buscar.
	 * @param filtro parametros de busqueda.
	*/
  find(modelo, filtro){
    const url_api = this.url_base + modelo + `?filter=${filtro}&access_token=${this.accessToken}`;
    return this.httclient.get<any>(url_api, { headers: this.headers })
  }

  /**
	 * Metodo para buscar modelos.
	 * @param modelo Modelo a buscar.
	 * @param filtro parametros de busqueda.
	*/
  findOne(modelo, filtro){
    const url_api = this.url_base + modelo + `/findOne?filter=${filtro}&access_token=${this.accessToken}`;
    return this.httclient.get<any>(url_api, { headers: this.headers })
  }

  /**
	 * Metodo para actualizar modelos.
	 * @param modelo Modelo a buscar.
	 * @param id id a actualizar.
	 * @param body datos a enviar.
     * 
	*/
  upsertWithWhere(modelo, id, body){
    const url_api = this.url_base + modelo + `/upsertWithWhere?[where][id]=${id}&access_token=${this.accessToken}`;
    return this.httclient.post<any>(url_api, body, { headers: this.headers}).pipe(map(data => data));
  }

  /**
	 * Metodo para create modelos.
	 * @param modelo Modelo a crear.
	 * @param body datos a enviar.
     * 
	*/
  create(modelo, body){
    const url_api = this.url_base + modelo + `?access_token=${this.accessToken}`;
    return this.httclient.post<any>(url_api, body, { headers: this.headers}).pipe(map(data => data));
  }

  /**
	 * Metodo para replaceOrCreate modelos.
	 * @param modelo Modelo a crear.
	 * @param body datos a enviar.
     * 
	*/
  replaceOrCreate(modelo, body){
    const url_api = this.url_base + modelo + `/replaceOrCreate?access_token=${this.accessToken}`;
    return this.httclient.post<any>(url_api, body, { headers: this.headers}).pipe(map(data => data));
  }

  /**
	 * Metodo remoto.
	 * @param modelo Modelo a crear.
	 * @param body datos a enviar.
     * 
	*/
  metodoRemotoPost(modelo, nombreMetodo, body){
    const url_api = this.url_base + modelo + `/`+nombreMetodo+ `?access_token=${this.accessToken}`;
    return this.httclient.post<any>(url_api, body, { headers: this.headers}).pipe(map(data => data));
  }

}
