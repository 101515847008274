import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatSnackBar} from '@angular/material';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Product } from '../../../models/product.model';
import { AuthService } from 'app/services/auth.service';


@Component({
    selector: 'agregar-producto',
    templateUrl: './agregar-producto.html',
    styleUrls: ['./agregar-producto.scss'],
    encapsulation: ViewEncapsulation.None
})

export class agregarProductoCompent implements OnInit{
    public listPaymentmMethod = [
        {  value: "PPD", option: "[PPD] - Pago en parcialidades o diferido" },
        {  value: "PUE", option: "[PUE] - Pago en una sola exhibición" }
    ];
     
    public listWayPay = [
        { value: "01", option: "[01] - Efectivo" },
        { value: "02", option: "[02] - Cheque nominativo" },
        { value: "03", option: "[03] - Transferencia electrónica de fondos" },
        { value: "04", option: "[04] - Tarjeta de crédito" },
        { value: "05", option: "[05] - Monedero electrónico" },
        { value: "06", option: "[06] - Dinero electrónico" },
        { value: "08", option: "[08] - Vales de despensa" },
        { value: "12", option: "[12] - Dación en pago" },
        { value: "13", option: "[13] - Pago por subrogación" },
        { value: "14", option: "[14] - Pago por consignación" },
        { value: "15", option: "[15] - Condonación" },
        { value: "17", option: "[17] - Compensación" },
        { value: "23", option: "[23] - Novación" },
        { value: "24", option: "[24] - Confusión" },
        { value: "25", option: "[25] - Remisión de deuda" },
        { value: "26", option: "[26] - Prescripción o caducidad" },
        { value: "27", option: "[27] - A satisfacción del acreedor" },
        { value: "28", option: "[28] - Tarjeta de débito" },
        { value: "29", option: "[29] - Tarjeta de servicios" },
        { value: "30", option: "[30] - Aplicación de anticipos" },
        { value: "99", option: "[99] - Por definir" }
    ];
    public listTypeVoucher = [
        { value: "D01", option: "[D01] - Honorarios médicos, dentales y gastos hospitalarios" },
        { value: "D02", option: "[D02] - Gastos médicos por incapacidad o discapacidad" },
        { value: "D03", option: "[D03] - Gastos funerales" },
        { value: "D04", option: "[D04] - Donativos" },
        { value: "D05", option: "[D05] - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
        { value: "D06", option: "[D06] - Aportaciones voluntarias al SAR" },
        { value: "D07", option: "[D07] - Primas por seguros de gastos médicos" },
        { value: "D08", option: "[D08] - Gastos de transportación escolar obligatoria" },
        { value: "D09", option: "[D09] - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
        { value: "D10", option: "[D10] - Pagos por servicios educativos (colegiaturas)" },
        { value: "G01", option: "[G01] - Adquisición de mercancias" },
        { value: "G02", option: "[G02] - Devoluciones, descuentos o bonificaciones" },
        { value: "G03", option: "[G03] - Gastos en general" },
        { value: "I01", option: "[I01] - Construcciones" },
        { value: "I02", option: "[I02] - Mobilario y equipo de oficina por inversiones" },
        { value: "I03", option: "[I03] - Equipo de transporte" },
        { value: "I04", option: "[I04] - Equipo de computo y accesorios" },
        { value: "I05", option: "[I05] - Dados, troqueles, moldes, matrices y herramental" },
        { value: "I06", option: "[I06] - Comunicaciones telefónicas" },
        { value: "I07", option: "[I07] - Comunicaciones satelitales" },
        { value: "I08", option: "[I08] - Otra maquinaria y equipo" },
        { value: "P01", option: "[P01] - Por definir" }
    ];
    product: Product;
    public cargando = false;
    private currentUser = localStorage.getItem("currentUser");
	public user: any = JSON.parse(this.currentUser);
    constructor(
        public dialogRef: MatDialogRef<agregarProductoCompent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private authService: AuthService
      ) { 
        console.log(this.data);
        this.product = new Product(this.data.element);
    
    }

    ngOnInit() {

    }

    guardarCliente(){
        this.cargando = true;
        this.product.companyUserId = this.user.id;
        let cantidad = this.product.cantidad;
        delete this.product.cantidad;
        this.authService.replaceOrCreate('products', this.product).subscribe(response => {
            this.cargando = false;
            this.product.cantidad = cantidad;
            this.dialogRef.close(this.product);
            this.snackBar.open('Producto registrado con exito','', {
                duration: 5000,
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        },error => {
            console.log('error', error)
            this.cargando = false;
            this.snackBar.open('Ha ocurrido un error, intentelo de nuevo','', {
                duration: 5000,
                horizontalPosition: "right",
                verticalPosition: "top",
            });
		})
    }
}
