export class Product{
    id: string;
    cantidad: number;
    identifiacdor: string;
    articulo: string;
    clave_sat: string;
    descripcion: string;
    unidad_sat: string;
    precio_unitario: number;
    impuesto1: string;
    impuesto2: string;
    impuesto3: string;
    companyUserId: string;

    constructor(Product?){
        Product = Product || {};
        this.id = Product.id || null;
        this.identifiacdor = Product.identifiacdor || '';
        this.cantidad = Product.cantidad || null;
        this.articulo = Product.articulo || '';
        this.clave_sat = Product.clave_sat || '';
        this.descripcion = Product.descripcion || '';
        this.unidad_sat = Product.unidad_sat || '';
        this.precio_unitario = Product.precio_unitario || '';
        this.impuesto1 = Product.impuesto1 || null;
        this.impuesto2 = Product.impuesto2 || null;
        this.impuesto3 = Product.impuesto3 || null;
        this.companyUserId = Product.companyUserId || '';
    }


    public static arrayObject(arreglo: any): Product[]{
        let arregloRe: Product[] = [];
        for (let value of arreglo) {
            arregloRe.push(new Product(value));
        }
        return arregloRe;
    }
}