import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatSnackBar} from '@angular/material';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Product } from '../../../models/product.model';
import { AuthService } from 'app/services/auth.service';


@Component({
    selector: 'pago-factura',
    templateUrl: './pago-factura.html',
    styleUrls: ['./pago-factura.scss'],
    encapsulation: ViewEncapsulation.None
})

export class pagoFacturaComponent implements OnInit{
    private company = JSON.parse(localStorage.getItem("company"));
    complemento: any = {
        NameId: '',
        Folio: '',
        ExpeditionPlace: '',
        Date: '',
        PaymentForm: '',
        Amount: null,
        Serie: '',
        Currency: '',
        PaymentMethod: '',
        PartialityNumber: 1,
    };
    public listPaymentmMethod = [
        {  value: "PPD", option: "[PPD] - Pago en parcialidades o diferido" },
        {  value: "PUE", option: "[PUE] - Pago en una sola exhibición" }
    ];
    public typeMoneda = [
        { value:"MXN", option: "MXN - Pesos"},
        // { value:"USD", option: "USD - Dolares"},
        // { value:"AED", option: "AED  - Dirham de los Emiratos Arabes Unidos"},
        // { value:"AFN", option: "AFN - Afgani afgano"},
        // { value:"ALL", option: "ALL - Lek albano"},
        // { value:"AMD", option: "AMD - Dram armenio"},
        // { value:"ANG", option: "ANG - Florin de las Antillas Holandesas"},
        // { value:"AOA", option: "AOA - Kwanza  angoleno"},
        // { value:"ARS", option: "ARS - Peso argentino"},
        // { value:"AUD", option: "AUD - Dolar australiano"},
        // { value:"AWG", option: "AWG - Florin arubeno"},
        // { value:"AZM", option: "AZM - Manat  azerbaiyano"},
        // { value:"BAM", option: "BAM - Marco convertible de Bosnia-Herzegovina"},
        // { value:"BBD", option: "BBD - Dolar de Barbados"},
        // { value:"BDT", option: "BDT - Taka  de Bangladesh"},
        // { value:"BGN", option: "BGN - Lev  bulgaro"},
        // { value:"BHD", option: "BHD - Dinar bahreini"},
        // { value:"BIF", option: "BIF - Franco burundes"},
        // { value:"BMD", option: "BMD - Dolar de Bermuda"},
        // { value:"BND", option: "BND - Dolar de Brunei"},
        // { value:"BOB", option: "BOB - Boliviano"},
        // { value:"BOV", option: "BOV - Mvdol  boliviano"},
        // { value:"BRL", option: "BRL - Real brasileno"},
        // { value:"BSD", option: "BSD - Dolar bahameno"},
        // { value:"BTN", option: "BTN - Ngultrum  de Butan"},
        // { value:"BWP", option: "BWP - Pula de Botswana"},
        // { value:"BYR", option: "BYR - Rublo bielorruso"},
        // { value:"BZD", option: "BZD - Dolar de Belice"},
        // { value:"CAD", option: "CAD - Dolar canadiense"},
        // { value:"CDF", option: "CDF - Franco congoleno"},
        // { value:"CHF", option: "CHF - Franco suizo"},
        // { value:"CLF", option: "CLF - Unidades de fomento chilenas"},
        // { value:"CLP", option: "CLP - Peso chileno"},
        // { value:"CNY", option: "CNY - Yuan Renminbi  de China"},
        // { value:"COP", option: "COP - Peso colombiano"},
        // { value:"COU", option: "COU - Unidad de valor real colombiana"},
        // { value:"CRC", option: "CRC - Colon costarricense"},
        // { value:"CUP", option: "CUP - Peso cubano"},
        // { value:"CVE", option: "CVE - Escudo caboverdiano"},
        // { value:"CZK", option: "CZK - Koruna  checo"},
        // { value:"DJF", option: "DJF - Franco yibutiano"},
        // { value:"DKK", option: "DKK - Corona danesa"},
        // { value:"DOP", option: "DOP - Peso dominicano"},
        // { value:"DZD", option: "DZD - Dinar algerino"},
        // { value:"EGP", option: "EGP - Libra egipcia"},
        // { value:"ERN", option: "ERN - Nakfa  eritreo"},
        // { value:"ETB", option: "ETB - Birr  etiope"},
        // { value:"EUR", option: "EUR - Euro"},
        // { value:"FJD", option: "FJD - Dolar fijiano"},
        // { value:"FKP", option: "FKP - Libra malvinense"},
        // { value:"GBP", option: "GBP - Libra esterlina"},
        // { value:"GEL", option: "GEL - Lari  georgiano"},
        // { value:"GHC", option: "GHC - Cedi  ghanes"},
        // { value:"GIP", option: "GIP - Libra de Gibraltar"},
        // { value:"GMD", option: "GMD - Dalasi  gambiano"},
        // { value:"GNF", option: "GNF - Franco guineano"},
        // { value:"GTQ", option: "GTQ - Quetzal guatemalteco"},
        // { value:"GYD", option: "GYD - Dolar guyanes"},
        // { value:"HKD", option: "HKD - Dolar de Hong Kong"},
        // { value:"HNL", option: "HNL - Lempira hondureno"},
        // { value:"HRK", option: "HRK - Kuna croata"},
        // { value:"HTG", option: "HTG - Gourde haitiano"},
        // { value:"HUF", option: "HUF - Forint  hungaro"},
        // { value:"IDR", option: "IDR - Rupiah  indonesia"},
        // { value:"ILS", option: "ILS - Nuevo shequel  israeli"},
        // { value:"INR", option: "INR - Rupia india"},
        // { value:"IQD", option: "IQD - Dinar iraqi"},
        // { value:"IRR", option: "IRR - Rial irani"},
        // { value:"ISK", option: "ISK - Krona  islandesa"},
        // { value:"JMD", option: "JMD - Dolar jamaicano"},
        // { value:"JOD", option: "JOD - Dinar jordano"},
        // { value:"JPY", option: "JPY - Yen japones"},
        // { value:"KES", option: "KES - Chelin keniata"},
        // { value:"KGS", option: "KGS - Som  kirguis"},
        // { value:"KHR", option: "KHR - Riel camboyano"},
        // { value:"KMF", option: "KMF - Franco comoriano"},
        // { value:"KPW", option: "KPW - Won norcoreano"},
        // { value:"KRW ", option: "KRW  - Won surcoreano"},
        // { value:"KWD", option: "KWD - Dinar kuwaiti"},
        // { value:"KYD", option: "KYD - Dolar caimano"},
        // { value:"KZT", option: "KZT - Tenge  kazajo"},
        // { value:"LAK", option: "LAK - Kip  lao"},
        // { value:"LBP", option: "LBP - Libra libanesa"},
        // { value:"LKR", option: "LKR - Rupia de Sri Lanka"},
        // { value:"LRD", option: "LRD - Dolar liberiano"},
        // { value:"LSL", option: "LSL - Loti lesothense"},
        // { value:"LYD", option: "LYD - Dinar libio"},
        // { value:"MAD", option: "MAD - Dirham  marroqui"},
        // { value:"MDL", option: "MDL - Leu moldavo"},
        // { value:"MGA", option: "MGA - Ariary  malgache"},
        // { value:"MKD", option: "MKD - Denar macedonio"},
        // { value:"MMK ", option: "MMK  - Kyat myanmaro"},
        // { value:"MNT", option: "MNT - Tughrik  mongol"},
        // { value:"MOP", option: "MOP - Pataca de Macao"},
        // { value:"MRO", option: "MRO - Ouguiya  mauritana"},
        // { value:"MUR", option: "MUR - Rupia  mauricia"},
        // { value:"MVR", option: "MVR - Rufiyaa  maldiva"},
        // { value:"MWK", option: "MWK - Kwacha malawiano"},
        // { value:"MXV", option: "MXV - Unidad de Inversion (UDI) mexicana"},
        // { value:"MYR", option: "MYR - Ringgit  malayo"},
        // { value:"MZM", option: "MZM - Metical mozambiqueno"},
        // { value:"NAD", option: "NAD - Dolar namibio"},
        // { value:"NGN", option: "NGN - Naira  nigeriana"},
        // { value:"NIO", option: "NIO - Cordoba nicaragÃ¼ense"},
        // { value:"NOK", option: "NOK - Corona noruega"},
        // { value:"NPR", option: "NPR - Rupia nepalesa"},
        // { value:"NZD", option: "NZD - Dolar neozelandes"},
        // { value:"OMR ", option: "OMR  - Rial omani"},
        // { value:"PAB", option: "PAB - Balboa panamena"},
        // { value:"PEN", option: "PEN - Nuevo sol peruano"},
        // { value:"PGK", option: "PGK - Kina  de Papua Nueva Guinea"},
        // { value:"PHP", option: "PHP - Peso filipino"},
        // { value:"PKR", option: "PKR - Rupia pakistani"},
        // { value:"PLN", option: "PLN - zloty polaco"},
        // { value:"PYG", option: "PYG - Guarani paraguayo"},
        // { value:"QAR", option: "QAR - Rial qatari"},
        // { value:"RON", option: "RON - Leu rumano"},
        // { value:"RUB", option: "RUB - Rublo ruso"},
        // { value:"RWF", option: "RWF - Franco ruandes"},
        // { value:"SAR", option: "SAR - Riyal saudi"},
        // { value:"SBD", option: "SBD - Dolar de las Islas Salomon"},
        // { value:"SCR", option: "SCR - Rupia de Seychelles"},
        // { value:"SEK", option: "SEK - Corona sueca"},
        // { value:"SGD", option: "SGD - Dolar de Singapur"},
        // { value:"SHP", option: "SHP - Libra de Santa Helena"},
        // { value:"SLL", option: "SLL - Leone  de Sierra Leona"},
        // { value:"SOS", option: "SOS - Chelin somali"},
        // { value:"SRD", option: "SRD - Dolar surinames"},
        // { value:"STD", option: "STD - Dobra  de Santo Tome y Principe"},
        // { value:"SYP", option: "SYP - Libra siria"},
        // { value:"SZL", option: "SZL - Lilangeni  suazi"},
        // { value:"THB", option: "THB - Baht tailandes"},
        // { value:"TJS", option: "TJS - Somoni tayik"},
        // { value:"TND", option: "TND - Dinar tunecino"},
        // { value:"TOP", option: "TOP - Pa'anga  tongano"},
        // { value:"TRY", option: "TRY - Nueva lira turca"},
        // { value:"TTD", option: "TTD - Dolar de Trinidad y Tobago"},
        // { value:"TWD", option: "TWD - Dolar taiwanes"},
        // { value:"TZS", option: "TZS - Chelin tanzano"},
        // { value:"UAH", option: "UAH - Hryvnia  ucraniana"},
        // { value:"UGX", option: "UGX - Chelin ugandes"},
        // { value:"USN", option: "USN - Dolar estadounidense (Siguiente dia)"},
        // { value:"UYU", option: "UYU - Peso uruguayo"},
        // { value:"UZS", option: "UZS - Som  uzbeco"},
        // { value:"VEF", option: "VEF - Bolivar fuerte venezolano"},
        // { value:"VND", option: "VND - Dong  vietnamita"},
        // { value:"VUV", option: "VUV - Vatu vanuatense"},
        // { value:"WST", option: "WST - Tala samoana"},
        // { value:"XAF", option: "XAF - Franco CFA"},
        // { value:"XAG", option: "XAG - Onza de plata"},
        // { value:"XAU", option: "XAU - Onza de oro"},
        // { value:"XBA", option: "XBA - European Composite  Unit  (EURCO)"},
        // { value:"XBB", option: "XBB - European MonetaryUnit  (E.M.U.-6)"},
        // { value:"XBC", option: "XBC - European Unit  of Account  9 (E.U.A.-9) "},
        // { value:"XBD", option: "XBD - European  Unit  of  Account  17 (E.U.A.-17)"},
        // { value:"XCD", option: "XCD - Dolar del Caribe Oriental"},
        // { value:"XDR", option: "XDR - Special Drawing Rights  (FMI)"},
        // { value:"XPD", option: "XPD - Onza de paladio"},
        // { value:"XPF", option: "XPF - Franco CFP"},
        // { value:"XPT", option: "XPT - Onza de platino"},
        // { value:"XTS", option: "XTS - Reservado para pruebas"},
        // { value:"YER", option: "YER - Rial yemeni"},
        // { value:"ZAR", option: "ZAR - Rand sudafricano"},
        // { value:"ZMK", option: "ZMK - Kwacha  zambiano"},
        // { value:"ZWD", option: "ZWD - Dolar zimbabuense"}
    ]
    public listWayPay = [
        { value: "01", option: "[01] - Efectivo" },
        { value: "02", option: "[02] - Cheque nominativo" },
        { value: "03", option: "[03] - Transferencia electrónica de fondos" },
        { value: "04", option: "[04] - Tarjeta de crédito" },
        { value: "05", option: "[05] - Monedero electrónico" },
        { value: "06", option: "[06] - Dinero electrónico" },
        { value: "08", option: "[08] - Vales de despensa" },
        { value: "12", option: "[12] - Dación en pago" },
        { value: "13", option: "[13] - Pago por subrogación" },
        { value: "14", option: "[14] - Pago por consignación" },
        { value: "15", option: "[15] - Condonación" },
        { value: "17", option: "[17] - Compensación" },
        { value: "23", option: "[23] - Novación" },
        { value: "24", option: "[24] - Confusión" },
        { value: "25", option: "[25] - Remisión de deuda" },
        { value: "26", option: "[26] - Prescripción o caducidad" },
        { value: "27", option: "[27] - A satisfacción del acreedor" },
        { value: "28", option: "[28] - Tarjeta de débito" },
        { value: "29", option: "[29] - Tarjeta de servicios" },
        { value: "30", option: "[30] - Aplicación de anticipos" },
        { value: "99", option: "[99] - Por definir" }
    ];
    @ViewChild('formulario') formValues
    dataSource: any;
    displayedColumns = ['uuid', 'fecha', 'nombre', 'total'];
    public info: any;
    public cargando = false;
    private currentUser = localStorage.getItem("currentUser");
	public user: any = JSON.parse(this.currentUser);
    constructor(
        public dialogRef: MatDialogRef<pagoFacturaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private authService: AuthService
      ) { 
        console.log(this.data);
        this.info = this.data.element;
        this.dataSource = this.data.data;
    }

    ngOnInit() {
        this.complemento.ExpeditionPlace = (this.company ? this.company.codigo_postal : '');

    }
    returnCantidadFormato(cantidad){
        return cantidad.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    guardar(){
        this.cargando = true;
        if(this.info.total == this.complemento.Amount){
            let data = {
                "Issuer": {
                  "FiscalRegime": "",
                  "Rfc": "",
                  "Name": ""
                },
                "Receiver": {
                  "Rfc": this.info.json.Receiver.Rfc,
                  "Name": this.info.json.Receiver.Name,
                  "CfdiUse": "P01"
                },
                "CfdiType": "P", // ASI DEJALO
                "NameId": this.complemento.NameId, // USURIO ESCRIBE
                "Folio": this.complemento.Folio, //USUARIO ESCRIBE
                "ExpeditionPlace": this.complemento.ExpeditionPlace, //USUARIO LO ESCRIBE
                "Complemento": {
                  "Payments": [{
                    "Date": this.complemento.Date, //USUARIO ESCRIBE
                    "PaymentForm": this.complemento.PaymentForm,  // USUARUO ESCRIBE - FORMA DE PAGO
                    "Amount": this.complemento.Amount, // USUARIO ESCRIBE
                    "RelatedDocuments": [{
                      "Uuid": this.info.uuid, //UUUIIUUD DE AL FACTURA
                      "Serie": this.complemento.Serie, //USUARIO ESCRIBE
                      "Folio": this.complemento.Folio, // EL MISMO DE ARRIBA XD
                      "Currency": this.complemento.Currency, //USUARIO ESCRIBE
                      "PaymentMethod": this.complemento.PaymentMethod, //USUARIO ESCRIBE -METODO DE PAGO
                      "PartialityNumber": this.complemento.PartialityNumber, // USUARIO ESCRIBE - DEFAULT 1
                      "PreviousBalanceAmount": this.info.total, //TOTAL DE LA FACTURA
                      "AmountPaid":  this.complemento.Amount, // LO MISMO DE ARRIBA
                      "ImpSaldoInsoluto": this.info.total - this.complemento.Amount // OPERACION ENTRE  PreviousBalanceAmount Y AmountPaid
                    }]
                  }]
                }
            }
            console.log('body: ', data);
            let body = {
                environment:	this.user.environment,
                companyId: this.company.id,
                data: data
            }
            this.authService.metodoRemotoPost('facturamas', 'createComplement', body).subscribe(res => {
                console.log('res: ', res);
                this.snackBar.open('Factura realizada con exito','', {
                    duration: 5000,
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
                this.dialogRef.close();
            }, err => {
                console.log('erro: ', err)
                this.cargando = false;
                let { error} = err;
                let message = error.error.ModelState;
                let arr = Object.values(message)
                var mess = (arr.length > 0 ? arr[0] : '')
                this.snackBar.open(mess.toString(),'', {
                    duration: 5000,
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
            })
        }else{
            this.formValues.form.controls['Amount'].setErrors('El monto debe ser igual al total');
            this.cargando = false;
        }
        
    }
}
