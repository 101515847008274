import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatSnackBar} from '@angular/material';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Product } from '../../../models/product.model';
import { AuthService } from 'app/services/auth.service';


@Component({
    selector: 'detalles',
    templateUrl: './detalles.html',
    styleUrls: ['./detalles.scss'],
    encapsulation: ViewEncapsulation.None
})

export class detallesComponent implements OnInit{
    private company = JSON.parse(localStorage.getItem("company"));
    public edit_description = false;
    complemento: any = {
        NameId: '',
        Folio: '',
        ExpeditionPlace: '',
        Date: '',
        PaymentForm: '',
        Amount: null,
        Serie: '',
        Currency: '',
        PaymentMethod: '',
        PartialityNumber: 1,
    };
   
    @ViewChild('formulario') formValues
    dataSource: any;
    displayedColumns = ['no','identificador', 'product_code', 'descripcion', 'unidad', 'total'];
    public info: any;
    public cargando = false;
    private currentUser = localStorage.getItem("currentUser");
	public user: any = JSON.parse(this.currentUser);
    constructor(
        public dialogRef: MatDialogRef<detallesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private authService: AuthService
      ) { 
        console.log(this.data);
        this.info = this.data.element;
        this.info.subtotal = (this.info.subtotal ? this.info.subtotal : 0)
        this.info.total = (this.info.total ? this.info.total : 0)
        this.dataSource = this.data.data;
    }

    ngOnInit() {
        this.complemento.ExpeditionPlace = (this.company ? this.company.codigo_postal : '');

    }
    returnCantidadFormato(cantidad){
        if(cantidad)
            return cantidad.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        else
            return 0;
    }

    saveDescriptionProduct(){
        this.edit_description = !this.edit_description
        console.log(this.data)
        let body = {
            data: this.data.element.data
        }
        console.log('Guardando...');
        console.log(body);
        this.authService.upsertWithWhere('billings', this.data.element.id, body).subscribe(data => {
            console.log(data);
            this.snackBar.open('CFDI actualizado','', {
                duration: 5000,
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        }, error => {
            console.log('error', error)
        });
    }

    guardar(){
        this.cargando = true;
        if(this.info.total == this.complemento.Amount){
            let data = {
                "Issuer": {
                  "FiscalRegime": "",
                  "Rfc": "",
                  "Name": ""
                },
                "Receiver": {
                  "Rfc": this.info.json.Receiver.Rfc,
                  "Name": this.info.json.Receiver.Name,
                  "CfdiUse": "P01"
                },
                "CfdiType": "P", // ASI DEJALO  
                "NameId": this.complemento.NameId, // USURIO ESCRIBE
                "Folio": this.complemento.Folio, //USUARIO ESCRIBE
                "ExpeditionPlace": this.complemento.ExpeditionPlace, //USUARIO LO ESCRIBE
                "Complemento": {
                  "Payments": [{
                    "Date": this.complemento.Date, //USUARIO ESCRIBE
                    "PaymentForm": this.complemento.PaymentForm,  // USUARUO ESCRIBE - FORMA DE PAGO
                    "Amount": this.complemento.Amount, // USUARIO ESCRIBE
                    "RelatedDocuments": [{
                      "Uuid": this.info.uuid, //UUUIIUUD DE AL FACTURA
                      "Serie": this.complemento.Serie, //USUARIO ESCRIBE
                      "Folio": this.complemento.Folio, // EL MISMO DE ARRIBA XD
                      "Currency": this.complemento.Currency, //USUARIO ESCRIBE
                      "PaymentMethod": this.complemento.PaymentMethod, //USUARIO ESCRIBE -METODO DE PAGO
                      "PartialityNumber": this.complemento.PartialityNumber, // USUARIO ESCRIBE - DEFAULT 1
                      "PreviousBalanceAmount": this.info.total, //TOTAL DE LA FACTURA
                      "AmountPaid":  this.complemento.Amount, // LO MISMO DE ARRIBA
                      "ImpSaldoInsoluto": this.info.total - this.complemento.Amount // OPERACION ENTRE  PreviousBalanceAmount Y AmountPaid
                    }]
                  }]
                }
            }
            console.log('body: ', data);
            let body = {
                environment:	this.user.environment,
                companyId: this.company.id,
                data: data
            }
            this.authService.metodoRemotoPost('facturamas', 'createComplement', body).subscribe(res => {
                console.log('res: ', res);
                this.snackBar.open('Factura realizada con exito','', {
                    duration: 5000,
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
                this.dialogRef.close();
            }, err => {
                console.log('erro: ', err)
                this.cargando = false;
                let { error} = err;
                let message = error.error.ModelState;
                let arr = Object.values(message)
                var mess = (arr.length > 0 ? arr[0] : '')
                this.snackBar.open(mess.toString(),'', {
                    duration: 5000,
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
            })
        }else{
            this.formValues.form.controls['Amount'].setErrors('El monto debe ser igual al total');
            this.cargando = false;
        }
        
    }
}
