import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router){

  }
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
      if(this.authService.getCurrentUser()){
        if(state.url.indexOf('/app') < 0){
            this.router.navigate(['/app']);
            return false;
        }else{
            return true;
        }
    }else {
        if(state.url.indexOf('/app') < 0){
            return true;
        }else{
            this.router.navigate(['/login']);
            return false;
        }
    }

  }
}
