import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatDialogModule, MatToolbarModule,MatFormFieldModule, MatInputModule, MatDatepickerModule, MatProgressSpinnerModule, MatSelectModule, MatSnackBarModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';

import { AppRoutingModule } from './app-routing.module'
import { agregarClienteCompent } from './main/facturacion/dialog-agregar-cliente/agregar-cliente';
import { agregarProductoCompent } from './main/facturacion/dialog-agregar-producto/agregar-producto';
import { pagoFacturaComponent } from './main/historial-facturacion/dialog-pago-factura/pago-factura';
import { detallesComponent } from './main/historial-facturacion/dialog-detalles/detalles';

@NgModule({
    declarations: [
        AppComponent,
        agregarClienteCompent,
        agregarProductoCompent,
        pagoFacturaComponent, 
        detallesComponent
    ],
    imports     : [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule, 
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTableModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MatProgressSpinnerModule
    ],
    bootstrap   : [
        AppComponent
    ],
    entryComponents: [agregarClienteCompent, agregarProductoCompent, pagoFacturaComponent, detallesComponent]
})
export class AppModule
{
}
